<template>
  <div class="sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl rounded-3xl ring-1 ring-darkgray-700 shadow lg:mx-0 lg:flex lg:max-w-none bg-darkgray-800">
        <div class="p-8 sm:p-10 lg:flex-auto">
          <h3 class="text-2xl font-bold tracking-tight text-white">
            Global licensing
          </h3>
          <p class="mt-6 text-base leading-7 text-gray-100">
            For businesses and companies. Includes multi-seat licence, team management and IP login security features. Cover your studio locations globally with a single custom license.
          </p>
          <div class="mt-10 flex items-center gap-x-4">
            <h4 class="flex-none text-sm font-semibold leading-6 text-orange-500">
              What’s included
            </h4>
            <div class="h-px flex-auto bg-darkgray-100"></div>
          </div>
          <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-100 sm:grid-cols-2 sm:gap-6">
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              All credits upfront
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Simple multi-site licensing
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              SAML Authentication (coming soon)
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Custom billing and purchase orders available
            </li>
          </ul>
        </div>
        <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div class="rounded-2xl bg-darkgray-600 shadow py-10 text-center ring-1 ring-inset ring-darkgray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div class="mx-auto max-w-xs px-8">
              <p class="text-base font-semibold text-white">
                Contact us for a custom quote
              </p>
              <p class="mt-6 flex items-baseline justify-center gap-x-2">
                <img src="~/assets/img/icon_white.png" loading="lazy" class="h-12" alt="MattePaint Icon" />
              </p>
              <a href="https://forms.clickup.com/9003088955/f/8ca0f1v-6596/99BACIQKJ2QH63LP0M" target="_blank" class="mt-10 block w-full rounded-md bg-orange-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Contact sales</a>
              <p class="mt-6 text-xs leading-5 text-gray-100">
                Let our friendly staff build a plan that suits your needs and license deliverables
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    doSupport() {
      this.$mitt.emit('open-support', true);
    }
  }
}
</script>
